
.footer{
    margin-bottom: 0;
    background-color: #211D21;
    display: flex;
    flex-direction: column-reverse;
}

@media only screen and (max-width: 600px) {
    .footer {
        height: 350px;
    }
}

.contact{
    height: auto;
    color: aliceblue;
    margin-left: 10px;
    margin-bottom: 30px;
}

.footer img{
    margin:20px 30px 2px 0;
}

a.icons svg{
    text-decoration: none;
    color: rgb(255, 255, 255);
    margin-right: 15px;
    height: 1.5em;
    width: 1.5em;
    transition: 0.2s;
}
a.icons svg:hover{
    height: 1.9em;
    width: 1.9em;
    color: red;
}
