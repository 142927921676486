.App {
    height: auto;
    display: grid;
    /* align-items: center; */
    justify-content: center;
    background-image: url("../../res/firstbgaagaz.png");
    padding-top: 10%;
    width: 100%;
    grid-template-columns: repeat(4,24%);
    grid-auto-rows: auto;
    row-gap : 4vh;
    scrollbar-width: 0px !important;
    scrollbar-color: white !important;
    scrollbar-face-color: white !important ;
    scrollbar-track-color: white;
    padding-left: 3%;

  }
#asas{
  width: 100%;
  grid-column: 1/-1;
  display: flex;
  justify-content: space-around;
padding-left: 20%;
padding-right: 20%;

}
  
  #asa:hover{
    background-color:#433636 !important ;

  }
  @media (max-width:700px) {
   .App{
    grid-template-columns: 50%;
    align-items: center;
    padding: 0;
    justify-content: center;
    padding-top:15vh ;
   } 
   #asas{
    flex-direction: column;
    padding: 0px;
    gap: 4vh;
   }
  }