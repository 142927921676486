.eventStyle1 {
    height: auto;
    width: 100%;
    background-color: rgb(33, 29, 33);
    color: white;
}

.eventStyle2 {
    height: auto;
    width: 100%;
    background-color: rgb(33, 29, 33);
    color: white;
}
.eventsArea{
    height:auto !important
}
.events div{
    width: auto !important;
    
}
.eventStyle1 .mainSection{
height: auto !important
}

.eventStyle3 {
    height: auto;
    width: 100%;
    background-color: rgb(33, 29, 33);
    color: white;
}

@media only screen and (max-width: 600px) {
    .eventStyle1 {
        height: auto;
    }
    .eventStyle2 {
      height  : auto;
    }
    .eventStyle3 {
        height: auto;
    }
}

.mainSection {
    padding: 50px;
    padding-top: 80px;
}

.dayChooserStyle {
    height: 70px;
    width: 100px;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.link {
    text-decoration: none;
    display: inline-block;
    transition: transform 50ms ease-in-out 25ms;
    color: white;
}

.link:hover {
    color: #f7141b;
    transform: translateY(-2px);
}

.dayChooserStyle:hover {
    color: #f7141b;
    cursor: pointer;
}

.choosedDayStyle {
    height: 70px;
    width: 100px;
    background-color: #f7141b;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.eventsArea {
    margin-top: 50px;
    height: 1200px;
    width: 90vw;
}

h1 {
    color: #f7141b;
    font-weight: 600;
}

h5 {
    font-style: oblique;
}

.eventPageSpaces {
    display: contents;
}

.eventPageBRs {
    display: none;
}

@media only screen and (max-width: 600px) {
    .eventPageSpaces {
        display: none;
    }
    
    .eventPageBRs {
        display: contents;
    }
    .events div{
    background-color: transparent !important;
    width: 80%;
        margin:.5rem !important
}
    .eventsArea{
    height:auto !important
}
.events div{
    width: auto !important;
    
}
.eventStyle1 .mainSection{
height: auto !important
}

}
.events div{
    background-color: transparent !important;
    width: 80%;
}