@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu&display=swap');

* {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #f7141b;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.adhyaayLogo {
  display: block;
}

.aboutStyleGrid {
  display: grid;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-template-columns: 100%;
  justify-content: center;
}

.aboutStyleGrid p{
  font-size: 17px;
}

.aboutStyleGridText {
  color: white;
  padding: 90px;
  text-align: center;
  font-size: 20px;
  width: 100%;
}

.aboutStyleGridPic {
  display: grid;
  align-items: center;
  height: 100%;
  margin: 0 20px 0 40px;
  padding-top: 0;
  padding-right: 50px;
}

@media only screen and (max-width: 600px) {
  .aboutStyleGrid {
      grid-template-columns: 1fr;
      height: auto;
      margin:auto
  }
  .aboutStyleGridPic {
    display: none;
  }
  .aboutStyleGridText {
    color: white;
    padding: 20px;
  }
}

a {
  text-decoration: none;
  color: red
}

a:hover {
  color: black;
}

a.icons svg {
  text-decoration: none;
  color: rgb(126, 0, 0);
  margin-right: 10px;
  height: 1.5em;
  width: 1.5em;
}

.mainPageAdhyaayLogo {
  transition: transform 250ms ease-in-out 20ms;
  height: 400px;
  /* transition: drop-shadow 100ms ease-in-out 50ms; */
}

.mainPageAdhyaayLogo:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.7)); 
}

.landingPage{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.landingPage Button{
  margin: 8vh;
  font-weight: 600;
  color: white;
  border-color: white;
  position: fixed;
  top: 40px;
  right: 35px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 84px 3px red;
}

@media only screen and (max-width: 880px) {
  .landingPage Button{
    position: relative;
    margin-top: 100px ;
    right: auto;
    font-weight: 600;
    color: white;
    border-color: white;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 80px 10px red;
    }
    .mainPageAdhyaayLogo {
      height: 250px;
    }
}