/* navbar.css */

.navbar {
  display: flex;
  align-items: center;
  width: auto;
  background-color: rgba(115, 49, 49, 0.5);
  top: 30px;
  left: 10%;
  position: absolute;
  border-radius: 15px;
  z-index: 1;
  transition: box-shadow 0.3s ease, top 0.3s ease;
  padding: 10px;
}

.navbar:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.full-width-navbar {
  width: 100vw;
  left: 0;
  right: 0;
  border-radius: 0;
}
.navLinks1 {
  text-decoration: none;
  color: white;
  font-weight: 500;
  margin: 0 10px 0 10px;
  transition: 0.3s;
  text-align: center;
}

.navLinks1:hover {
  border-bottom: 2px solid red;
  color: aliceblue;
  font-size: 16px;
}

.navLinks2 {
  border: 2px rgb(255, 253, 253);
}
/* Override Bootstrap styles for mobile menu */
@media (max-width: 767px) { 
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(115, 49, 49, 0.8);
    padding: 15px;
    z-index: 1000;
  }

  .navbar-nav {
    margin-top: 15rem;
    width: 100%;
    text-align: center;
  }

  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 1001;
  }

  .navLinks1 {
    font-size: 20px;
  }
  .navLinks1:hover {
    border-bottom: none;
    font-size: 20px;
  }
}